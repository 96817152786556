import axios from "axios";
import { defaultHeaders, appendCSRFToken } from "./utils";

const createAccount = user => {
  const baseHeaders = defaultHeaders();
  const headers = appendCSRFToken(baseHeaders);
  return axios.post(
    "/users",
    {
      captcha: user.captcha,
      utm_source: user.utmSource,
      email: user.email,
      password: user.password
    },
    {
      headers: {
        ...headers
      }
    }
  );
};

const sendConfirmationEmail = username => axios.post(`/users/${username}/send_confirmation_email.json`);

const sendResetPasswordEmail = email => axios.post("/auth/send_reset_password_email", { email });

const resetPassword = (userId, token, password) => {
  const baseHeaders = defaultHeaders();
  const headers = appendCSRFToken(baseHeaders);

  return axios.put(
    "/auth/update_password",
    {
      user_id: userId,
      token,
      password_reset: { password }
    },
    {
      headers: {
        ...headers
      }
    }
  );
};
export const users = {
  createAccount,
  resetPassword,
  sendConfirmationEmail,
  sendResetPasswordEmail
};
