import styled from "styled-components";

export const ConnectWalletButton = styled.button`
  width: min(100%, 392px);
  min-height: 40px;
  padding: 4px 16px;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
  border: 0px;
  color: #0b0d0e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;

  &:hover {
    background: #f0f4f8;
  }
`;
