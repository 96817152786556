import React from "react";
import { warning } from "src/utils/colors";

const Icon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5933 7.02L8.97335 0.400001C8.71475 0.142711 8.3648 -0.00172424 8.00002 -0.00172424C7.63523 -0.00172424 7.28528 0.142711 7.02668 0.400001L0.406682 7.02667C0.278484 7.15427 0.176757 7.30595 0.107344 7.47298C0.0379301 7.64002 0.00219727 7.81912 0.00219727 8C0.00219727 8.18088 0.0379301 8.35998 0.107344 8.52702C0.176757 8.69405 0.278484 8.84573 0.406682 8.97333L7.02668 15.5867C7.15216 15.7183 7.30324 15.8229 7.47062 15.894C7.63801 15.965 7.81817 16.0011 8.00002 16C8.18212 16.0002 8.36247 15.9644 8.53066 15.8946C8.69886 15.8248 8.85158 15.7224 8.98002 15.5933L15.5933 8.98C15.8531 8.71999 15.999 8.36751 15.999 8C15.999 7.63249 15.8531 7.28001 15.5933 7.02ZM7.33335 4.28C7.33335 4.10319 7.40359 3.93362 7.52861 3.8086C7.65364 3.68357 7.8232 3.61333 8.00002 3.61333C8.17683 3.61333 8.3464 3.68357 8.47142 3.8086C8.59645 3.93362 8.66668 4.10319 8.66668 4.28V8.28C8.66668 8.45681 8.59645 8.62638 8.47142 8.7514C8.3464 8.87643 8.17683 8.94667 8.00002 8.94667C7.8232 8.94667 7.65364 8.87643 7.52861 8.7514C7.40359 8.62638 7.33335 8.45681 7.33335 8.28V4.28ZM8.03335 11.9533C7.77127 11.9502 7.52031 11.8469 7.3319 11.6647C7.1435 11.4825 7.03191 11.2352 7.02002 10.9733C7.01462 10.8413 7.03598 10.7096 7.08281 10.586C7.12964 10.4625 7.20097 10.3496 7.29252 10.2544C7.38406 10.1591 7.49393 10.0833 7.61551 10.0316C7.73709 9.97983 7.86788 9.95322 8.00002 9.95333C8.26027 9.95824 8.50881 10.0625 8.69472 10.2447C8.88063 10.4268 8.98985 10.6732 9.00002 10.9333C9.00446 11.064 8.98288 11.1943 8.93654 11.3165C8.8902 11.4388 8.82001 11.5507 8.73007 11.6456C8.64013 11.7405 8.53221 11.8166 8.41261 11.8694C8.293 11.9222 8.16408 11.9508 8.03335 11.9533Z"
      fill={warning}
    />
  </svg>
);

export default Icon;
