import React from "react";

export const Success = ({ email }) => {
  return (
    <>
      <div className="input-form">
        <h4 className="h4">Check your email</h4>
        <p className="body-md">
          Lastly, please check your inbox and confirm your email: <strong>{email}</strong>
        </p>
      </div>
    </>
  );
};
