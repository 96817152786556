import React, { useState, useEffect } from "react";
import {
  ForgotPasswordPage as StyledForgotPasswordPage,
  Title,
  Subtitle,
  PasswordInput,
  PasswordForm,
  PasswordButton,
  Logo
} from "./styled";
import { users } from "../../../api/users";
import { toast } from "react-toastify";
import { ToastBody } from "src/components/design_system/toasts";

export const SetPasswordPage = props => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const url = new URL(window.location.href);

  const urlParams = {
    token: url.searchParams.get("token"),
    userId: url.searchParams.get("user_id")
  };

  const parsedProps = { ...props, ...urlParams };

  useEffect(() => {
    if (password.length >= 8 && password === confirmPassword) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [password, confirmPassword]);

  const onSubmit = e => {
    e.preventDefault();

    setIsSaveDisabled(true);

    if (password !== confirmPassword) {
      toast.error(<ToastBody heading="Passwords do not match." />, { autoClose: 2000 });
      return;
    }

    if (password.length < 8) {
      toast.error(<ToastBody heading="Password should have at least 8 characters." />, { autoClose: 2000 });
      return;
    }

    if (!parsedProps.userId || !parsedProps.token) {
      toast.error(<ToastBody heading="Invalid reset token." />, { autoClose: 2000 });
      return;
    }

    users
      .resetPassword(parsedProps.userId, parsedProps.token, password)
      .then(() => {
        toast.success(<ToastBody heading="Password updated. You will be redirected to sign in soon." />, {
          autoClose: 2000
        });
        setTimeout(() => {
          window.location.replace("/");
        }, 2000);
      })
      .catch(() => {
        toast.error(
          <ToastBody
            heading="Error!"
            body={"Something went wrong. Your reset token might have expired. Ask for a new reset email again."}
          />,
          {
            autoClose: 3500
          }
        );
      });

    setIsSaveDisabled(false);
  };

  return (
    <StyledForgotPasswordPage>
      <Logo src={parsedProps.logo_url} />
      <Title>Create a new password</Title>
      <Subtitle>Passwords should have at least 8 characters.</Subtitle>
      <PasswordForm onSubmit={onSubmit}>
        <PasswordInput
          type={"password"}
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <PasswordInput
          type={"password"}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <PasswordButton disabled={isSaveDisabled}>Save password</PasswordButton>
      </PasswordForm>
    </StyledForgotPasswordPage>
  );
};

export default (props, railsContext) => {
  return () => <SetPasswordPage {...props} railsContext={railsContext} />;
};
