import { Button } from "./button";
import React from "react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { arbitrum, avalanche, base, celo, fantom, linea, mainnet, optimism, polygon, zora } from "wagmi/chains";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "aea162f85e70b25b8dd88ce0e57fb571";

// 2. Create wagmiConfig
const metadata = {
  name: "Login Talent Protocol",
  description: "",
  url: location.origin,
  icons: ["https://talentprotocol.com/images/favicon.jpg"]
};

const chains = [mainnet, polygon, optimism, arbitrum, base, zora, celo, avalanche, fantom, linea];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableCoinbase: true,
  auth: {
    email: false, // default to true
    showWallets: true, // default to true
    walletFeatures: true // default to true
  }
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  allowUnsupportedChain: true,
  themeVariables: {
    "--w3m-z-index": 2000
  }
});

export const SignInWalletButton = ({ text, redirect_url }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Button text={text} redirect_url={redirect_url} />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default (props, railsContext) => {
  return () => <SignInWalletButton {...props} railsContext={railsContext} />;
};
