import { ConnectWalletButton } from "./styled";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useWeb3Modal, useWeb3ModalEvents } from "@web3modal/wagmi/react";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "src/components/icons";

export const Button = ({ text, redirect_url }) => {
  const { signMessageAsync } = useSignMessage();
  const { address, isConnected, chainId } = useAccount();
  const [status, setStatus] = useState("idle");
  const [loading, setLoading] = useState(false);

  const getNonce = async address => {
    const nonce = await axios.post("auth/login_nonces", { address }).then(res => res.data);
    return nonce.nonce;
  };

  const login = useMutation({
    mutationFn: async signature => {
      const res = await axios.post("auth/wallet_login", {
        address: address?.toLowerCase(),
        signature,
        chain_id: chainId
      });
      return res;
    }
  });

  // const { connectAsync } = useConnect();
  const { open } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect();
  const { data } = useWeb3ModalEvents();

  useEffect(() => {
    if (data.event === "SELECT_WALLET" && status === "idle") {
      setStatus("connecting");
    } else if (["CONNECT_SUCCESS", "MODAL_CLOSE"].includes(data.event) && status === "connecting" && isConnected) {
      setStatus("signing");
      setLoading(true);
      getNonce(address).then(nonce => {
        const message = `Sign in with Talent Protocol\nnonce: ${nonce}`;

        signMessageAsync({ message: message })
          .then(signature => {
            setStatus("idle");
            login
              .mutateAsync(signature)
              .then(response => {
                setLoading(false);
                window.location.href = `${redirect_url}?auth_token=${response.data.auth_token}`;
              })
              .catch(err => {
                toast.error(`An error occured while connecting wallet: ${err.response.data?.error || err.message}`, {
                  autoClose: 3000
                });
                console.error(err);
                setLoading(false);
                setStatus("idle");
                disconnectAsync();
              });
          })
          .catch(err => {
            console.error(err);
            setLoading(false);
            setStatus("idle");
            disconnectAsync();
          });
      });
    } else if (data.event === "CONNECT_ERROR" && status === "connecting") {
      setStatus("idle");
      console.error("Error connecting wallet");
    }
  }, [login, data, signMessageAsync, status, isConnected]);

  const handleLinkWallet = async () => {
    await disconnectAsync();
    open();
  };

  return (
    <ConnectWalletButton type="button" onClick={handleLinkWallet}>
      {loading ? <Spinner width={16} className="mx-4" /> : text}
    </ConnectWalletButton>
  );
};
