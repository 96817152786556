import styled from "styled-components";

export const ForgotPasswordPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  min-width: min(100%, 392px);
  padding-left: 16px;
  padding-right: 16px;
`;

export const Title = styled.h4`
  margin-top: 40px;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  color: #f0f4f8;
  text-align: center;
`;

export const Subtitle = styled.p`
  width: min(100%, 392px);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #f0f4f8;
  text-align: center;
  margin-bottom: 40px;
`;

export const EmailForm = styled.form`
  width: min(100%, 392px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const EmailInput = styled.input`
  width: min(100%, 392px);
  border-radius: 12px;
  border: 1px solid #32383e;
  background: #0b0d0e;
  padding: 8px 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #f0f4f8;

  &::placeholder {
    color: #9fa6ad;
  }

  &:focus {
    border: 1px solid #f0f4f8;
  }

  &:focus-visible {
    outline: none;
  }
`;

export const EmailButton = styled.button`
  width: min(100%, 392px);
  min-height: 40px;
  padding: 4px 16px;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
  border: 0px;
  color: #0b0d0e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;

  &:hover {
    background: #f0f4f8;
  }

  &:disabled {
    background: #171a1c;
  }
`;

export const Logo = styled.img`
  position: fixed;
  top: 16px;
  left: calc(50% - 12px);
  color: #f0f4f8;
`;
