import React, { useState } from "react";
import { EmailButton as PasswordButton, EmailInput as PasswordInput, EmailForm as PasswordForm } from "./styled";
import ReCAPTCHA from "react-google-recaptcha";

export const Password = ({ next, captchaKey }) => {
  const [localPassword, setLocalPassword] = useState("");
  const [localPasswordConfirmation, setLocalPasswordConfirmation] = useState("");
  const [localCaptcha, setLocalCaptcha] = useState("");
  const onSubmit = e => {
    e.preventDefault();
    next(localPassword, localCaptcha);
  };

  return (
    <>
      <div className="input-form">
        <h4 className="h4">Create Password</h4>
        <p className="body-md">Password should have at least 8 characters.</p>
        <PasswordForm onSubmit={onSubmit}>
          <PasswordInput
            type="password"
            placeholder="Password"
            value={localPassword}
            onChange={e => setLocalPassword(e.target.value)}
            required
          />
          <PasswordInput
            type="password"
            placeholder="Password confirmation"
            value={localPasswordConfirmation}
            onChange={e => setLocalPasswordConfirmation(e.target.value)}
            required
          />
          <ReCAPTCHA sitekey={captchaKey} onChange={v => setLocalCaptcha(v)} />
          <PasswordButton
            type="submit"
            disabled={
              localPassword === "" ||
              localPassword.length < 8 ||
              localPassword !== localPasswordConfirmation ||
              !localCaptcha
            }
          >
            Continue
          </PasswordButton>
        </PasswordForm>
      </div>
    </>
  );
};
