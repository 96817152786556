import React, { useEffect, useState } from "react";
import {
  ForgotPasswordPage as StyledForgotPasswordPage,
  Title,
  Subtitle,
  EmailInput,
  EmailForm,
  EmailButton,
  Logo
} from "./styled";
import { users } from "../../../api/users";
import { toast } from "react-toastify";
import { ToastBody } from "src/components/design_system/toasts";

export const ForgotPasswordPage = ({ logo_url }) => {
  const [email, setEmail] = useState("");
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const onSubmit = e => {
    e.preventDefault();

    users
      .sendResetPasswordEmail(email)
      .then(() => {
        toast.success(<ToastBody heading="Email sent. Check your inbox." />, { autoClose: 2000 });
      })
      .catch(() => {
        toast.error(<ToastBody heading="Unable to send password reset email. Reach out to us if it persists." />, {
          autoClose: 2000
        });
      });
  };

  useEffect(() => {
    if (email.includes("@") && email.includes(".")) {
      setIsContinueDisabled(false);
    } else {
      setIsContinueDisabled(true);
    }
  }, [email]);

  return (
    <StyledForgotPasswordPage>
      <Logo src={logo_url} />
      <Title>Reset Password</Title>
      <Subtitle>Please tell us the email associated with your Talent Passport.</Subtitle>
      <EmailForm onSubmit={onSubmit}>
        <EmailInput type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
        <EmailButton disabled={isContinueDisabled}>Continue</EmailButton>
      </EmailForm>
    </StyledForgotPasswordPage>
  );
};

export default (props, railsContext) => {
  return () => <ForgotPasswordPage {...props} railsContext={railsContext} />;
};
