import React from "react";
import { string, number } from "prop-types";

const Polygon = ({ className, pathClassName, style, width }) => (
  <svg
    className={className}
    viewBox={"0 0 16 16"}
    width={`${width}px`}
    height={`${width}px`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
  >
    <path
      className={pathClassName}
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="white"
    />
    <path
      className={pathClassName}
      d="M13.3332 8.1869V10.6004C13.3323 10.7509 13.2925 10.8986 13.2177 11.0291C13.143 11.1595 13.0357 11.2683 12.9065 11.3447L10.8232 12.5489C10.6941 12.6261 10.5467 12.6668 10.3965 12.6668C10.2463 12.6668 10.0989 12.6261 9.96984 12.5489L7.8865 11.3447C7.75728 11.2683 7.65004 11.1595 7.57526 11.0291C7.50048 10.8986 7.4607 10.7509 7.45984 10.6004V9.923L8.5265 9.30166V10.4791L10.3932 11.5663L12.2598 10.4791V8.30982L10.3932 7.22266L6.01984 9.76494C5.8896 9.83858 5.74264 9.87728 5.59316 9.87728C5.44368 9.87728 5.29674 9.83858 5.1665 9.76494L3.08316 8.5557C2.95594 8.47812 2.85076 8.36896 2.77778 8.23874C2.7048 8.10852 2.66648 7.96164 2.6665 7.81224V5.39874C2.66736 5.24824 2.70714 5.10054 2.78192 4.97008C2.85672 4.83962 2.96396 4.73086 3.09316 4.65446L5.1765 3.45022C5.3058 3.3738 5.45312 3.3335 5.60316 3.3335C5.75322 3.3335 5.90054 3.3738 6.02984 3.45022L8.11316 4.65446C8.24238 4.73086 8.34962 4.83962 8.4244 4.97008C8.4992 5.10054 8.53896 5.24824 8.53984 5.39874V6.07612L7.4665 6.6933V5.52252L5.59984 4.43536L3.73316 5.52252V7.6893L5.59984 8.77646L9.97316 6.23418C10.1034 6.16054 10.2504 6.12186 10.3998 6.12186C10.5493 6.12186 10.6963 6.16054 10.8265 6.23418L12.9098 7.44344C13.0383 7.5202 13.1448 7.629 13.219 7.75926C13.2932 7.88954 13.3325 8.03686 13.3332 8.1869Z"
      fill="#7B3FE4"
    />
  </svg>
);

Polygon.defaultProps = {
  className: "",
  pathClassName: "",
  width: 16
};

Polygon.propTypes = {
  className: string,
  pathClassName: string,
  width: number
};

export default Polygon;
