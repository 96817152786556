// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs";

import "channels";

import ReactOnRails from "react-on-rails";

import FlashMessages from "src/components/FlashMessages";
import SignUpPage from "src/components-v2/pages/sign-up";
import SignInWalletButton from "src/components-v2/sign-in-wallet-button";
import ForgotPasswordPage from "src/components-v2/pages/forgot-password";
import SetPasswordPage from "src/components-v2/pages/set-password";

import "stylesheets/application.scss";

// eslint-disable-next-line no-undef
require.context("../images", true);

ReactOnRails.register({
  FlashMessages,
  SignUpPage,
  ForgotPasswordPage,
  SetPasswordPage,
  SignInWalletButton
});

Rails.start();

// TO ENABLE ACTIVE STORAGE LATER
// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()
